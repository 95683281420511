import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';

const IndexWrapper = styled.main`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 0 20px 20px;

	@media (min-width: 1024px) {
		padding: 0 50px;
		flex-direction: row;
		justify-content: flex-start;
	}
`;

const MobileProject = styled.section`
	margin-bottom: 20px;
	img {
		max-width: 100%;
		width: 100%;
		margin-bottom: 10px;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
`;

const ProjectLink = styled(Link)`
	text-decoration: none;
	color: inherit;
	font-size: 20px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;

	@media (min-width: 1024px) {
		position: relative;
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
		margin-bottom: 40px;

		/* &:nth-of-type(1),
		&:nth-of-type(3),
		&:nth-of-type(4),
		&:nth-of-type(6),
		&:nth-of-type(8),
		&:nth-of-type(9),
		&:nth-of-type(11),
		&:nth-of-type(13),
		&:nth-of-type(14),
		&:nth-of-type(16) { */

		&:nth-of-type(5n + 1),
		&:nth-of-type(5n + 3),
		&:nth-of-type(5n + 4) {
			margin-right: 20px;
		}
		&:nth-of-type(5n + 1),
		&:nth-of-type(5n + 2),
		&:nth-of-type(5n + 4) {
			width: calc(50% - 10px);
		}

		&:nth-of-type(5n + 3),
		&:nth-of-type(5n + 5) {
			width: calc(25% - 15px);
		}
	}
`;

const ProjectAsset = styled.aside`
	box-sizing: border-box;
	background-size: 0;
	flex: 1;
	margin-bottom: 20px;

	@media (min-width: 1024px) {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#24A2E0')};

		img {
			display: none;
		}
	}
`;

const ProjectCover = styled.div`
	display: none;
	@media (min-width: 1024px) {
		display: block;
		background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#24A2E0')};
		opacity: 0;
		padding: 20px;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: opacity 0.25s ease;
		p {
			transform: translateY(-25px);
			opacity: 0;
			transition: transform 0.25s ease, opacity 0.25s ease;
		}

		&.hover {
			opacity: 1;
			p {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
`;

const ProjectTitle = styled.p`
	color: inherit;
	font-family: 'GrotexRegular';
	margin-bottom: 5px;
	font-size: 1.3333333333rem;

	@media (min-width: 1024px) {
		font-size: 1.5rem;
		line-height: 1.8666666667rem;
		margin: 0;
	}
`;

const Rollover = styled.p`
	font-family: 'GrotexLight';
	color: white;
	font-size: 1.3333333333rem;
	line-height: 2rem;

	@media (min-width: 1024px) {
		font-size: 1.5rem;
		line-height: 1.8666666667rem;
	}
`;

const Subtitle = styled.p`
	font-family: 'GrotexLight';

	@media (min-width: 1024px) {
		position: absolute;
		bottom: -5px;
		font-size: 1.1111111111rem;
		line-height: 1.3333333333rem;
	}
`;

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		{
			allSanitySiteSettings {
				nodes {
					description
				}
			}
			sanityHome {
				projectOrder {
					title
					subtitle
					rolloverText
					coverColor {
						hex
					}
					slug {
						current
					}
					cover {
						asset {
							url
							fluid {
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						}
					}
				}
			}
		}
	`);
	const [projects] = useState(data.sanityHome.projectOrder);

	const isTabletOrMobile = useMediaQuery({
		query: '(max-width: 1024px)',
	});

	const AddHover = element => {
		const coverElement = element.currentTarget.querySelector('.cover');
		coverElement.classList.add('hover');
	};

	const RemoveHover = element => {
		const coverElement = element.currentTarget.querySelector('.cover');
		coverElement.classList.remove('hover');
	};

	return (
		<Layout>
			<SEO title='Home' description={data.allSanitySiteSettings.nodes[0].description} />

			<IndexWrapper>
				{projects && (
					<React.Fragment>
						{projects.map((proj, index) => {
							return isTabletOrMobile ? (
								<MobileProject key={index}>
									<Link to={`/${proj.slug.current.toLowerCase()}`}>
										<img src={proj.cover.asset.url} alt={proj.title} />
										<ProjectTitle>{proj.title}</ProjectTitle>
										<Subtitle>{proj.subtitle && `- ${proj.subtitle}`}</Subtitle>
									</Link>
								</MobileProject>
							) : (
								<ProjectLink to={`/${proj.slug.current.toLowerCase()}`} key={index} onMouseEnter={AddHover} onMouseLeave={RemoveHover}>
									<ProjectAsset
										style={{
											backgroundImage: `url(${proj.cover.asset.url}?q=100)`,
										}}
										backgroundColor={proj.coverColor && proj.coverColor.hex}>
										<ProjectCover backgroundColor={proj.coverColor && proj.coverColor.hex} className='cover'>
											<Rollover>{proj.rolloverText && `${proj.rolloverText}`}</Rollover>
										</ProjectCover>
										<Img fluid={proj.cover.asset.fluid} alt={proj.title} />
									</ProjectAsset>
									<ProjectTitle>{proj.title}</ProjectTitle>
									<Subtitle> {proj.subtitle && `- ${proj.subtitle}`}</Subtitle>
								</ProjectLink>
							)
						})}
					</React.Fragment>
				)}
			</IndexWrapper>
		</Layout>
	);
};

export default IndexPage;
